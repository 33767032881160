import * as React from 'react';

export interface SVGIconProps {
    width?: string;
    height?: string;
    viewBox?: string;
    fill?: string;
    style?: React.CSSProperties;
    className?: string;
    children?: React.ReactNode;
    stroke?: string;
    transform?: string;
    strokeWidth?: string;
    strokeLinecap?: string;
    strokeLinejoin?: string;
}

export const DEFAULT_SVG_COLOR = '#455666';

export function SVGIcon(props: SVGIconProps) {
    return (
        <svg
            width={props.width || '100%'}
            style={props.style}
            height={props.height || '100%'}
            viewBox={props.viewBox}
            className={props.className}
            preserveAspectRatio="xMidYMid"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill={props.fill || DEFAULT_SVG_COLOR}
        >
            {props.children}
        </svg>
    );
}
