import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getAllGeneratedContentList } from '../../CustomContent/CustomContentConstant';
import GettingStarted from './content/GettingStarted';
import Models from './content/Models';
import Endpoints from './content/Endpoints';
import './GeneratedContent.scss';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import {
    GeneratedContentKeyForSidenav,
    SideNavActiveItemStoreKey,
} from '../../../views/ViewConstant';
import AdditionalSDKDocs from './content/AdditionalSDKDocs';

const GeneratedContent: FunctionComponent = () => {
    const { key } = useParams();

    const [invalidGeneratedContet, setInvalidGeneratedContent] = useState(false);

    const { updateStore } = useStore() as StoreProviderValue;

    useEffect(() => {
        const indexKey = getAllGeneratedContentList().findIndex(
            (item) =>
                item.from.toLowerCase().toLowerCase().replace(' ', '-') ===
                key?.toLowerCase().toLowerCase().replace(' ', '-')
        );

        if (indexKey === -1) {
            setInvalidGeneratedContent(true);
        } else {
            updateStore(GeneratedContentKeyForSidenav + '-' + key, SideNavActiveItemStoreKey);
            setInvalidGeneratedContent(false);
        }
    }, [key]);

    const show = (val: string) => {
        return key?.toLowerCase().toLowerCase().replace(' ', '-') === val;
    };

    return (
        <div className="w-full px-10 py-6">
            {invalidGeneratedContet && <div>We could not find what you are looking for</div>}

            {!invalidGeneratedContet && (
                <div className="w-1/2">
                    {show('getting-started') && <GettingStarted />}
                    {show('models') && <Models />}
                    {show('endpoints') && <Endpoints />}
                    {show('sdk-infra') && <AdditionalSDKDocs />}
                </div>
            )}
        </div>
    );
};

export default GeneratedContent;
