import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const AdditionalDocsIcon = (props: SVGIconProps) => {
    return (
        <SVGIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
            fill="none"
            style={{ fill: 'none !important' }}
            className="additional-doc-icon"
        >
            <path
                strokeWidth="3"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                d="M14.5 22H18a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v4"
            />
            <path
                strokeWidth="3"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                d="M14 2v4a2 2 0 0 0 2 2h4"
            />
            <path
                strokeWidth="3"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                d="M3 13.1a2 2 0 0 0-1 1.76v3.24a2 2 0 0 0 .97 1.78L6 21.7a2 2 0 0 0 2.03.01L11 19.9a2 2 0 0 0 1-1.76V14.9a2 2 0 0 0-.97-1.78L8 11.3a2 2 0 0 0-2.03-.01Z"
            />
            <path strokeWidth="3" stroke={props.fill || DEFAULT_SVG_COLOR} d="M7 17v5" />
            <path
                strokeWidth="3"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                d="M11.7 14.2 7 17l-4.7-2.8"
            />
        </SVGIcon>
    );
};
