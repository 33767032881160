import React, { FunctionComponent } from 'react';
import { H4 } from '../../../../design-system/Headings/Headings';

const AdditionalSDKDocs: FunctionComponent = () => {
    return (
        <>
            <H4 className="mt-6 mb-4 font-medium">SDK Infrastructure</H4>
            <p className="pb-2 leading-relaxed">
                This section will be automatically generated by APIMatic when you publish a portal
                for any given programming language.
            </p>
            <p className="pb-2 leading-relaxed">
                It will document various helpful SDK components such as:
            </p>
            <ul
                style={{ listStyle: 'disc', listStylePosition: 'inside' }}
                className="pb-2 leading-relaxed"
            >
                <li>Helper methods</li>
                <li>Utility classes</li>
                <li>Response/Exception handling code</li>
            </ul>
            <p className="leading-relaxed">
                Note that the exact content will depend on the selected language and code generation
                settings defined with your API Specification. For more details on code generation
                settings, see{' '}
                <a
                    style={{ color: '#0c7ff2' }}
                    href="https://docs.apimatic.io/generate-sdks/codegen-settings/codegen-settings-overview/"
                >
                    here
                </a>
                .
            </p>
        </>
    );
};

export default AdditionalSDKDocs;
